import { Link } from "gatsby"
import React, { useEffect } from "react"

import GalleryOrOrganisation from "../../images/Gallery-or-organisation.png"
import UserOrCollector from "../../images/User-or-collector.png"
import ArtistOrCollective from "../../images/Artist-or-collective.png"

import * as styles from "./SignUp.module.scss"
import Seo from "../../components/Seo/seo"

const SignUp = () => {
  useEffect(() => {
    const main = document.querySelector("main") as unknown as HTMLElement
    main.setAttribute("style", "height: 100%;")
  }, [])

  return (
    <>
      <Seo title="Sign up" />
      <div className={styles.signUpContainer}>
        <div className={styles.signUpHeading}>
          <h1>Sign Ups</h1>
          <span>Are you a -</span>
        </div>
        <div className={styles.signUp}>
          <Link to="/sign-up/artist">
            <img src={ArtistOrCollective} alt="Artist or Collection Link" />
          </Link>
          <Link to="/sign-up/gallery">
            <img
              src={GalleryOrOrganisation}
              alt="Gallery or Organization Link"
            />
          </Link>
          <Link to="/create-account">
            <img src={UserOrCollector} alt="User or Collector Link" />
          </Link>
        </div>
      </div>
    </>
  )
}

export default SignUp
